import React, { useState } from 'react';
import styled from 'styled-components';
import lighten from 'polished/lib/color/lighten';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Confetti from 'components/event/Confetti';
import Section from 'components/common/Section';
import Modal from 'components/common/Modal';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CalendarToday from '@material-ui/icons/CalendarToday';
import AccessTime from '@material-ui/icons/AccessTime';
import LocationOn from '@material-ui/icons/LocationOn';
import Divider from '@material-ui/core/Divider';
import Button from 'components/common/Button';
import { H1, H2, Paragraph } from "components/common/typo";

const Hero = styled.div`
  position: relative;
  z-index: 2;
  min-height: 30em;
  background-color:  ${({ theme }) => lighten(0.38, theme.colors.primaryLight)};
`;

const SvgContainer = styled.svg`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 10vw;
`;

const DetailHeader = styled(H2)`
  font-weight: normal;
`;

const DetailSection = styled(Section)`
  align-items: flex-start;
`;

const FloatSection = styled(Section)`
  align-items: flex-start;
  position: absolute;
  bottom: -3em;
`;

const EventCard = styled(Paper)`
  && {
    width: ${({ sm }) => sm ? null : '40vw'};
    z-index: 1;
    border-radius: 15px;
    background-color: white;
    padding: ${({ theme }) => theme.spacing.xl};
    box-shadow: 0 0 40px 0 rgba(0,0,0,.10);
  }
`;

const CalendarTodayIcon = styled(CalendarToday)`
  color: ${({ theme }) => theme.colors.lightgray };
`;

const AccessTodayIcon = styled(AccessTime)`
  color: ${({ theme }) => theme.colors.lightgray };
`;

const LocationOnIcon = styled(LocationOn)`
  color: ${({ theme }) => theme.colors.lightgray };
`;

const YesButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing.md};
`;

const GoogleDoc = styled.iframe`
  width: 80vw;
  height: 80vh;
  border: none;
`;

const Description = styled(Paragraph)`
  margin-bottom: ${({ theme }) => theme.spacing.md};
  color: black;
`;

const EVENT = {
  title: 'Dibya Karma - The coding SANTA',

  descriptions: ['This December, in the cold season, Dibya is coming with the warmest of gifts, <b>"Dibya karma - the coding SANTA" </b>',
    'On <b>26<sup>th</sup> December</b>, <b>Dibya</b> is organizing <b> "Dibya karma - the coding SANTA" </b> to encourage all the young developers at colleges having hurdles completing their projects. We provide expert help and mentorship on a full-day Free workshop.',
    'Our focus will mainly be on the following aspects:',
    '1. Software design issues and database modeling',
    '2. Debugging and general problem solving',
    '3. Common deployment practices and making app live in public'
  ]
};

const SRC = 'https://docs.google.com/forms/d/e/1FAIpQLSfRR3De81buzdbCA3azNK_7WFiSVHddxC0soDkBGTXuiCElPw/viewform?embedded=true';

function HeroSection() {
  return (
    <Hero>
      <FloatSection>
        <H1>{EVENT.title}</H1>
        <EventDetailsCard />
      </FloatSection>
      <SvgContainer xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
        <polygon fill="white" points="0,100 100,0 100,100"/>
      </SvgContainer>
      <Confetti />
    </Hero>
  );
}

function EventDetailsCard() {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const [modalOpen, setModalOpen] = useState(false);

  function modalToggle() {
    setModalOpen(!modalOpen);
  }

  return (
    <EventCard sm={sm}>
      <Grid container spacing={1}>
        <Grid item xs={2} sm={2}><CalendarTodayIcon /></Grid>
        <Grid item xs={10} sm={10}>26th December, 2020</Grid>
        <Grid item xs={2} sm={2}><AccessTodayIcon /></Grid>
        <Grid item xs={10} sm={10}>08:00 AM - 4:00 PM</Grid>
        <Grid item xs={2} sm={2}><LocationOnIcon /></Grid>
        <Grid item xs={10} sm={10}>World Wide Web</Grid>
        <Grid item xs={12}>
          <Divider light />
        </Grid>
        <Grid item xs={12}>
          <YesButton onClick={modalToggle} small filled>Reserve my Seat!</YesButton>
        </Grid>
        <Modal isOpen={modalOpen} onCancel={modalToggle}>
          <GoogleDoc src={SRC} />
        </Modal>
      </Grid>
    </EventCard>
  );
}

function EventDetails() {
  return (
    <DetailSection>
      <DetailHeader>Event Details</DetailHeader>
      {
        EVENT.descriptions.map(desc => (<Description dangerouslySetInnerHTML={{__html: desc}} />))
      }
    </DetailSection>
  );
}

export default function EventPage() {
  return (
    <>
      <HeroSection />
      <EventDetails />
    </>
  );
}
