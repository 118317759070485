import React, { createRef } from 'react';
import { Page } from 'components/common/Page';
import Hero from 'components/event';
import { Subscribe } from 'components/home/Subscribe';

export default function Home() {
  const subscribeElement = createRef();

  function goToSubscribe() {
    subscribeElement.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }

  return (
    <Page goToSubscribe={goToSubscribe}>
      <Hero />
      <Subscribe reference={subscribeElement}/>
    </Page>
  );
}
