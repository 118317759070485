// @flow
import React from 'react';
import styled from 'styled-components';
import { InputBase } from '@material-ui/core';
import { SendMail } from './Icon';

const Container = styled.div`
  display: flex;
  padding: 0.5em 1.5em;
  border-radius: 48px;
  border: ${({ theme, border }) => border && `solid 1px ${theme.colors.primaryLight}`};
  background: ${({ theme }) => theme.colors.white};
  max-width: 200px;
  margin-top: ${({ theme }) => theme.spacing.md};
  cursor: pointer;
`;

type inputProps = {
  border?: boolean;
  IconBefore?: React$Element;
  IconAfter?: React$Element;
};

function Input(props: inputProps) {
  const { border, IconAfter, IconBefore } = props;

  return (
    <Container border={border}>
      {IconBefore && <IconBefore />}
      <InputBase fullWidth {...props} />
      {IconAfter && <IconAfter onClick={() => props.onSubmit && props.onSubmit()} />}
    </Container>
  );
}

export function SubscribeInput(props) {
  return <Input IconAfter={SendMail} {...props} />
}

export default Input;
