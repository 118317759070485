import React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

import Section from 'components/common/Section';
import {H3, Paragraph} from 'components/common/typo';

import Online from 'assets/features/icon-mentor.svg';
import Portfolio from 'assets/features/icon-portfolio.svg';
import Resume from 'assets/features/icon-resume.svg'

const FEATURES = [
  {
    icon: Online,
    title: 'One to One Mentorship',
    desc: `Unlike conventional teaching methods, our mentors from industry will help you learn through code reviews and one-to-one interactions, solving real-world problems. Guiding you to the right skills set in order to achieve full professional potential.`
  },
  {
    icon: Resume,
    title: 'Industry Ready',
    desc: `We strongly believe in learning by doing. Our project-based curriculum will help you build portfolios on the domain of your interest so that your skill set outruns your competitors.`
  },
  {
    icon: Portfolio,
    title: 'Matching with companies',
    desc: `We provide career advice and expand your professional network. We also connect you with our partner companies and collaborate with you in every step to make sure you land a great job.`
  }
];

const FeatureIcon = styled.img`
  height: 4.5em;
  width: auto;
  margin-bottom: ${({ theme }) => theme.spacing.md};;
`;

const FeatureHeader = styled(H3)`
  font-weight: normal;
  text-align: center;
`;

const FeatureDesc = styled(Paragraph)`
  text-align: center;
  font-size: 16px;
`;

const FeatureWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.xl};
`;

export function Feature() {
  return (
    <Section>
      <Grid spacing={1} container>
        {
          FEATURES.map(({ icon, title, desc }) => (
            <FeatureWrapper item xs={12} sm={4} key={title.toString()}>
              <FeatureIcon src={icon}/>
              <FeatureHeader>{title}</FeatureHeader>
              <FeatureDesc>{desc}</FeatureDesc>
            </FeatureWrapper>
          ))
        }
      </Grid>
    </Section>
  )
}
