// @flow
import React, { useContext } from 'react';
import styled, { ThemeContext }from 'styled-components';
import SvgIcon from '@material-ui/core/SvgIcon';

const Icon = styled(SvgIcon)`
  && {
    width: 36px;
    height: 27px;
    padding-left: 1em;
    margin-bottom: -4px;
  }
`;

type arrowType = {
  color: string
}

export default function Arrow(props: arrowType) {
  const theme = useContext(ThemeContext);
  const { color = theme.colors.primaryLight } = props;

  return (
    <Icon viewBox="0 0 36 27">
      <path
        style={{fill: color}}
        d="M21.205,5.007c-0.429-0.444-1.143-0.444-1.587,0c-0.429,0.429-0.429,1.143,0,1.571l8.047,8.047H1.111
        C0.492,14.626,0,15.118,0,15.737c0,0.619,0.492,1.127,1.111,1.127h26.554l-8.047,8.032c-0.429,0.444-0.429,1.159,0,1.587
        c0.444,0.444,1.159,0.444,1.587,0l9.952-9.952c0.444-0.429,0.444-1.143,0-1.571L21.205,5.007z"/>
    </Icon>
  )
}

const SendMailIcon = styled(SvgIcon)`
  && {
    height: 1.1875em;
    padding-left: 5px;
  }
`;

export function SendMail(props) {
  const theme = useContext(ThemeContext);

  return (
    <SendMailIcon viewBox="0 0 334.5 334.5" {...props}>
      <path
        color={theme.colors.lightgray}
        d="M332.797,13.699c-1.489-1.306-3.608-1.609-5.404-0.776L2.893,163.695c-1.747,0.812-2.872,2.555-2.893,4.481
        s1.067,3.693,2.797,4.542l91.833,45.068c1.684,0.827,3.692,0.64,5.196-0.484l89.287-66.734l-70.094,72.1
        c-1,1.029-1.51,2.438-1.4,3.868l6.979,90.889c0.155,2.014,1.505,3.736,3.424,4.367c0.513,0.168,1.04,0.25,1.561,0.25
        c1.429,0,2.819-0.613,3.786-1.733l48.742-56.482l60.255,28.79c1.308,0.625,2.822,0.651,4.151,0.073
        c1.329-0.579,2.341-1.705,2.775-3.087L334.27,18.956C334.864,17.066,334.285,15.005,332.797,13.699z"/>
    </SendMailIcon>
  )
}
