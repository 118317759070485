// @flow
import React from "react";

import Header, { HeaderProps } from "./Header";
import Hero, { Props as HeroProps } from "./Hero";
import Footer from "./Footer";

type PageProps = {
  children: ?React.Node,
} & HeaderProps;

export const Page = ({ children, goToSubscribe }: PageProps) => (
  <React.Fragment>
    <Header goToSubscribe={goToSubscribe}/>
    {children}
    <Footer />
  </React.Fragment>
);

type HeroPageProps = {
  children: ?React.Node,
  goToSubscribe: Function,
  heroProps: HeroProps
};

export const HeroPage = ({ children, heroProps, goToSubscribe }: HeroPageProps) => (
  <React.Fragment>
    <Header goToSubscribe={goToSubscribe}/>
    <Hero {...heroProps } />
    {children}
    <Footer />
  </React.Fragment>
);
