// @flow
import React from "react";
import styled from "styled-components";
import { Grid } from "@material-ui/core";

import { H2, Paragraph } from "./typo";

const TitleGrid = styled(Grid)`
  margin-bottom: 32px !important;
`;

const SectionTitle = styled(H2)`
  font-weight: normal;
  text-align: center;
`;

type TitledSectionProps = {
  title: string,
  children: React.Node
};

export function TitledSection({ title, children }: TitledSectionProps) {
  return (
    <Section>
      <TitleGrid item xs={12}>
        <SectionTitle>{title}</SectionTitle>
      </TitleGrid>
      {children}
    </Section>
  );
}

const ServiceGrid = styled(Grid)`
  flex-direction: ${({ align }) => (align === "left" ? "row" : "row-reverse")};
  margin-top: 64px;
  padding: 0 50px;
  @media (max-width: 600px) {
    padding: 0 16px;
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;

const Service = styled(H2)`
  color: ${({ theme }) => theme.colors.success};
`;

type ServiceProps = {
  align: string,
  title: string,
  service: string,
  desc: string,
  img: string
};

export const ServiceSection = ({
  align,
  title,
  service,
  desc,
  img
}: ServiceProps) => (
  <ServiceGrid container xs={12} align={align}>
    <Grid item xs={12} sm={6}>
      <Image src={img} />
    </Grid>
    <Grid item xs={12} sm={6}>
      <H2>{title}</H2>
      <Service>{service}</Service>
      <Paragraph>{desc}</Paragraph>
    </Grid>
  </ServiceGrid>
);

const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing.xxxl} 50px`};
  @media (max-width: 600px) {
    padding: ${({ theme }) => `${theme.spacing.xxxl} 16px`};
  }
`;

export default Section;
