// @flow
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom'
import { isMobile } from 'react-device-detect';
import { Menu, Close } from '@material-ui/icons';

import Logo from 'assets/logo.png';

import Button from './Button';

const HeaderWrapper = styled.div`
  position: sticky;
  display: flex;
  z-index: 999;
  justify-content: space-between;
  align-items: center;
  top: 0;
  background-color: ${({ showMenu, theme }) =>
  showMenu ? theme.colors.primaryLight : theme.colors.white};
  opacity: ${({ showMenu }) => showMenu ? '0.87' : null};
  box-shadow: ${({ showMenu }) => showMenu ? null : '0 2px 4px 0 rgba(0,0,0,.1)'};
  padding: 16px 50px;
  @media (max-width: 600px) {
    padding: 16px 16px;
  }
`;

const HeaderLogo = styled.img`
  height: 28px;
`;

const HeaderBar = styled(Menu)`
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
`;

const HeaderClose = styled(Close)`
  color: white;
  cursor: pointer;
`;

const MMenuWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  opacity: 0.87;
  z-index: 998;
  background: ${({ theme }) => theme.colors.primaryLight};
`;

const MMenuItem = styled.span`
  color: white;
  font-size: 48px;
  font-weight: bold;
  cursor: pointer;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.primary};
  padding: ${({ theme }) => theme.spacing.sm};
  margin: ${({ theme }) => `0 ${theme.spacing.sm}`};
  
  background-image: ${({ theme }) => `linear-gradient(${theme.colors.primary}, ${theme.colors.primary})`};
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 2px;
  transition: background-size .3s;
  :hover, :active, :focus {
    background-size: 100% 2px;
  }
`;

const DMenuWrapper = styled.div``;

const menuItems = [
  {
    text: "Home",
    link: "/"
  },
  {
    text: "Event",
    link: "/events"
  }
];

export type HeaderProps = {
  goToSubscribe: Function
};

export default function Header({ goToSubscribe }: HeaderProps) {
  const [showMenu, setShowMenu] = useState(false);
  const [underscore, setUnderscore] = useState(true);

  useEffect(() => {
    const timerID = setInterval( () => setUnderscore(!underscore), 700);

    return function cleanup() {
      clearInterval(timerID);
    };
  });

  const toggleMenu = () => setShowMenu(!showMenu);

  const renderHamburger = () => (
    <>
      {showMenu ? (
        <HeaderClose onClick={toggleMenu} />
      ) : (
        <HeaderBar onClick={toggleMenu} />
      )}
    </>
  );

  const renderMenu = () => {
    return (
      <DMenuWrapper>
        {menuItems.map(({ text, link }) => (
          <StyledLink to={link} key={text}>
            {text}
          </StyledLink>
        ))}
        <Button onClick={goToSubscribe} small filled>Subscribe</Button>
      </DMenuWrapper>
    )
  };

  return (
    <React.Fragment>
      <HeaderWrapper isMobile={isMobile} showMenu={showMenu}>
        <HeaderLogo src={Logo} />
        {
          isMobile ? renderHamburger() : renderMenu()
        }
      </HeaderWrapper>
      {showMenu && isMobile && (
        <MMenuWrapper>
          {menuItems.map(({ text, link }) => (
            <Link to={link}>
              <MMenuItem>{text}</MMenuItem>
            </Link>
          ))}
        </MMenuWrapper>
      )}
    </React.Fragment>
  );
}
