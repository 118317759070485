import React from 'react';
import Section from 'components/common/Section';
import { SubscribeInput } from 'components/common/Input';
import { H2 } from 'components/common/typo';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as platform from 'platform';
import { identity, pickBy } from 'lodash';

const SubscribeSection = styled(Section)`
  background: linear-gradient(to right, rgba(38,186,171,0.9) 0%,rgba(38,127,186,0.8) 61%,rgba(38,127,186,0.9) 100%);
`;

const SubscribeTitle = styled(H2)`
  font-weight: normal;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
`;

const SubscriptionNotice = styled.p`
  color: #F5DD45;
`;

const SubscriptionProgress = styled(CircularProgress)`
  margin-top: 10px;
`;

type SubscribeType = {
  reference: any
}

export function Subscribe(props: SubscribeType) {
  const [email, setEmail] = React.useState(null);
  const [message, setMessage] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const setInput = (e) => {
    setEmail(e.target.value);
    if(e.key === 'Enter') subscribe();
  }

  const subscribe = () => {
    setMessage(null);
    setLoading(true);
    fetch(
      'https://06ta8iv6j1.execute-api.us-east-2.amazonaws.com/Prod/subscribe', {
        method: 'POST',
        headers: {
          'x-api-key': 'Wc8vGpKtTL3ylmoBl6hm09N2cMouKMsL48kmdMoG'
        },
        body: JSON.stringify({ email: email, platform: pickBy(platform, identity) })
      }
    ).then(response => {
      setLoading(false);
      response.json().then(data => {
        console.log(JSON.stringify(data));
        setMessage(data.body);
      })
    })
  }

  const { reference } = props;

  return (
    <SubscribeSection ref={reference}>
      <SubscribeTitle>Subscribe for early access</SubscribeTitle>
      <SubscribeInput onKeyUp={setInput} onSubmit={subscribe} />
      { loading && (
          <SubscriptionProgress color='secondary' />
      )}
      { message && (
          <SubscriptionNotice>{message}</SubscriptionNotice>
      )}
    </SubscribeSection>
  )
}
