import React from 'react';
import Section from 'components/common/Section';
import { H2 } from 'components/common/typo';
import styled from 'styled-components';

export const StyledSection = styled(Section)`
  background: linear-gradient(to right, rgba(38,186,171,0.9) 0%,rgba(38,127,186,0.8) 61%,rgba(38,127,186,0.9) 100%);
  justify-content: space-around;
`

const StyledTitle = styled(H2)`
  font-weight: normal;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
`

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 80%;
  margin-bottom: 0.75em;
`

const DiscordBanner = styled.img`
  border-radius: .8em;
  opacity: 0.85;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
  &:hover {
    opacity: 1;
  }
`

const BannerCard = styled.div`
  border-radius: .8em;
  transition: 0.5s ease-out;
  &:hover {
    transform: translateY(-0.2em);
  }
`

export const JoinDiscord = () =>(

  <StyledSection>
    <StyledWrapper>
      <StyledTitle>
        Join us in Discord >
      </StyledTitle>
      <BannerCard>
        <DiscordBanner
          src="https://discordapp.com/api/guilds/592685249062895617/widget.png?style=banner2"
          onClick={()=> window.open("https://discord.gg/eJZCXjJ2uG", "_blank")}
        />
      </BannerCard>
    </StyledWrapper>
    <widgetbot
      server="592685249062895617"
      channel="785103753346285568"
      width="80%"
      height="70vh"
    ></widgetbot>
  </StyledSection>
)
