import React from 'react';
import styled from 'styled-components';
import Stepper from '@material-ui/core/Stepper/index';
import Step from '@material-ui/core/Step/index';
import StepButton from '@material-ui/core/StepButton';

import { Paragraph } from 'components/common/typo';
import Button from 'components/common/Button';

import { TitledSection } from 'components/common/Section';

const StyledStepper = styled(Stepper)`
  width: 80%;
  margin: 0 auto;
  box-sizing: border-box;
  @media (max-width: 600px) {
    width: 100%; 
  }
`;

const DescContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 70%;
  @media (max-width: 600px) {
    width: 80%; 
  }
`;

const Description = styled(Paragraph)`
  text-align: center;
  min-height: 60px;
  font-weight: normal;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spacing.md};
`;

const STEPS = [
  {
    title: 'Submit application',
    desc: `You will need to submit application on the platform when it's the right time.
           We open session every 2-3 months, you can apply for one of the coming session.
           Application will be available on our website.`
  },
  {
    title: 'On-site test',
    desc: `Upon meticulously reviewing your application, we will select you for the test.
           On-site test includes a 2 hr on-site coding test followed a short interview.`
  },
  {
    title: 'Start Learning',
    desc: `After you are selected from your test and interview, you will be invited to join
           dibya. You will be assigned a mentor who will be guiding you through out the procress.
           dibya app will have your course content, progress tracking etc.`
  },
  {
    title: 'Match with company',
    desc: `After you are selected from your test and interview, you will be invited to join
           dibya. You will be assigned a mentor who will be guiding you through out the procress.
           dibya app will have your course content, progress tracking etc.`
  }
];

export default function VerticalLinearStepper() {
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = STEPS.map(({ title }) => title);

  function handleNext() {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  }

  function handleBack() {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }

  const handleStep = step => () => {
    setActiveStep(step);
  };

  const getContent = step => STEPS[step].desc;

  return (
    <TitledSection title="How it works?">
      <StyledStepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepButton onClick={handleStep(index)} completed={false}>{label}</StepButton>
          </Step>
        ))}
      </StyledStepper>
      <DescContainer>
        <Description>{getContent(activeStep)}</Description>
        <ButtonContainer>
          <Button
            small
            onClick={handleBack}
            disabled={activeStep === 0}
          >
            Back
          </Button>
          <Button
            filled
            small
            onClick={handleNext}
            disabled={activeStep === steps.length - 1}
          >
            Next
          </Button>
        </ButtonContainer>
      </DescContainer>
    </TitledSection>
  );
}
