// @flow
import React from 'react';
import styled from 'styled-components';

import Grid from '@material-ui/core/Grid';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from 'components/common/Button';

const Title = styled.div`
  font-size: 36px;
  color: ${({ theme }) => theme.colors.primary};
  margin: ${({ theme }) => theme.spacing.md } 0;
`;

const Desc = styled.div`
  font-size: 24px;
  color: ${({ theme }) => theme.colors.success};
`;

const HeroImage = styled.img`
  float: right;
  width: 100%;
  height: auto;
`;

const HeroAction = styled(Button)`
  margin-top: ${({ smalldevice }) => smalldevice ? '48px' : '64px'};
`;

const HeroDescription = styled(Grid)`
  && {
    ${({ smalldevice }) => smalldevice ? `
        margin-top: 24px;
        margin-bottom: 48px;
      ` : `
        margin-top: 10vh;
      `};
  }
`;

const HeroWrapper = styled.div`
  position: relative;
  margin-bottom: 70px;
  &:before {
    height: 600px;
    content: "";
    width: 100%;
    display: block;
    position: absolute;
  }
`;

const HeroSection = styled.div`
  position: relative;
  padding: ${({ smalldevice }) => smalldevice ? '24px 16px 0' : '24px 50px 0'};
`;

export type Props = {
  title: string,
  desc: string,
  img: ?string,
  button: ?string,
  onClick: ?Function
};

export default function Hero({ title, desc, img, button, onClick }: Props) {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <HeroWrapper>
      <HeroSection smalldevice={sm}>
        <Grid container spacing={1} justify="center" alignItems='center'>
          <HeroDescription item xs={12} sm={6} smalldevice={sm}>
            <Title>{title}</Title>
            <Desc>{desc}</Desc>
            {button && <HeroAction smalldevice={sm} onClick={onClick}>{button}</HeroAction>}
          </HeroDescription>
          <Grid item xs={12} sm={6}>
            {img && <HeroImage src={img} />}
          </Grid>
        </Grid>
      </HeroSection>
    </HeroWrapper>
  );
}
