import React from 'react';
import styled, {keyframes} from 'styled-components';

import Confetti1 from '../../assets/confetti/confetti_1.svg';
import Confetti2 from '../../assets/confetti/confetti_2.svg';
import Confetti3 from '../../assets/confetti/confetti_3.svg';
import Confetti4 from '../../assets/confetti/confetti_4.svg';
import Confetti5 from '../../assets/confetti/confetti_5.svg';
import Confetti6 from '../../assets/confetti/confetti_6.svg';

const confettiIcons = [
  Confetti1,
  Confetti2,
  Confetti3,
  Confetti4,
  Confetti5,
  Confetti6
];

const roundClockWise = keyframes`
  from { transform: rotate(0deg) translateX(15px) rotate(0deg); }
  to   { transform: rotate(360deg) translateX(15px) rotate(-360deg); }
`;

const roundAntiClockWise = keyframes`
  from { transform: rotate(360deg) translateX(15px) rotate(-360deg); }
  to   { transform: rotate(0deg) translateX(15px) rotate(0deg); }
`;

const scale = keyframes`
  0% { transform: scale(1); }
  50%   { transform: scale(1.5); }
  100%   { transform: scale(1); }
`;

const animations = [
  roundClockWise,
  roundAntiClockWise,
  scale
];

const Confetti = styled.img`
  position: absolute;
  z-index: 0;
  left: ${({ left }) => `${left}`};
  top: ${({ top }) => `${top}`};
  height: ${({ height }) => `${height}px`};
  width: auto;
  opacity: 0.4;
  animation: ${({ animation }) => animation} 10s linear infinite;
`;

const positions = [
  {
    left: '10%',
    top: '20%'
  },
  {
    left: '5%',
    top: '50%'
  },
  {
    left: '24%',
    top: '40%'
  },
  {
    left: '32%',
    top: '10%'
  },
  {
    left: '14%',
    top: '70%'
  },
  {
    left: '45%',
    top: '75%'
  },
  {
    left: '45%',
    top: '30%'
  },
  {
    left: '30%',
    top: '70%'
  },
  {
    left: '5%',
    top: '85%'
  },
  {
    left: '60%',
    top: '14%'
  },
  {
    left: '56%',
    top: '45%'
  },
  {
    left: '73%',
    top: '10%'
  },
  {
    left: '89%',
    top: '56%'
  },
  {
    left: '67%',
    top: '65%'
  },
  {
    left: '75%',
    top: '40%'
  },
  {
    left: '90%',
    top: '25%'
  },
  {
    left: '90%',
    top: '70%'
  },
  {
    left: '73%',
    top: '10%'
  },
  {
    left: '80%',
    top: '45%'
  },
  {
    left: '60%',
    top: '70%'
  },
];

// NOTE: all dimensions are given in px
function confettiOptions(wrapperHeight, index) {
  const confettiHeight = 15 + parseFloat((Math.random() * 10).toFixed(2));
  const { top, left } = positions[index];
  const animation = animations[Math.floor(Math.random() * animations.length)];

  return {
    top,
    left,
    animation,
    height: confettiHeight
  }
}

export default function ConfettiWrapper() {
  const confetties = new Array(16).fill(0).map((_, index) => ({
    options: confettiOptions(400, index),
    confettiIcon: confettiIcons[index % confettiIcons.length]
  }));

  return (
    <>
      {
        confetties.map(({ options, confettiIcon }, index) =>
          <Confetti src={confettiIcon} {...options} key={index.toString()} />)
      }
    </>
  );
}
