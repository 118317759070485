// @flow
import styled from "styled-components";

export const H1 = styled.h1`
  font-size: 48px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary};
  margin: 0 0 16px 0;
`;

export const H2 = styled.h2`
  font-size: 32px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary};
  margin: 0 0 16px 0;
`;

export const H3 = styled.h3`
  font-size: 24px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary};
  margin: 0 0 16px 0;
`;

export const Title = styled(H2)`
  color: ${({ theme }) => theme.colors.primaryLight};
  margin: 0;
  text-align: center;
`;

export const Paragraph = styled.p`
  font-size: 18px;
  line-height: 1.5;
  text-align: left;
  color: ${({ theme }) => theme.colors.lightgray};
  margin: 0;
`;
