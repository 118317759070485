// @flow
import React from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Link } from 'react-router-dom';

import facebook from "assets/social/facebook@2x.png";
import twitter from "assets/social/twitter@2x.png";
import github from "assets/social/github@2x.png";
import linkedin from "assets/social/linkedin@2x.png";
import medium from "assets/social/medium@2x.png";

const FooterWrapper = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  border-radius: 100%/2 30px;
  background-color: ${({ theme }) => theme.colors.background};
`;

const FooterContainer = styled.div`
  width: 100%;
  padding: ${({theme}) => theme.spacing.xxl};
`;

const Tag = styled.div`
  font-size: 1.5em;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.success};
`;

const Desc = styled.p`
  font-size: 16px;
  line-height: 1.56;
  color: rgba(112, 112, 112, 0.54);
`;

const EmailLink = styled.a`
  text-decoration: none;
`;

const Email = styled(Desc)`
  color: ${({ theme }) => theme.colors.success};
`;

const LinkWrapper = styled.a`
  display: flex;
  align-items: center;
  padding: 10px 0;
`;

const Anchor = styled.a`
  color: ${({ theme }) => theme.colors.lightgray};
  font-weight: bold;
  cursor: pointer;
  font-size: 12px;
  text-decoration: none;
`;

const Icon = styled.img`
  margin-right: 5px;
  height: 18px;
`;

const IconContainer = styled.div`
  margin-top: ${({ smallDevice, theme }) =>
  smallDevice ? theme.spacing.xxl : theme.spacing.md};
`;

type LinkItemIconProps = {
  icon: React.Node,
  text: string,
  link: string
};

const LinkItemIcon = ({ icon, text, link }: LinkItemIconProps) => (
  <LinkWrapper href={link} target="_blank">
    <Icon src={icon} alt={text}/>
  </LinkWrapper>
);

type LinkItemProps = {
  text: string,
  link: string
};

// eslint-disable-next-line
const LinkItem = ({ text, link }: LinkItemProps) => (
  <LinkWrapper>
    <Link href={link}>
      <Anchor>{text}</Anchor>
    </Link>
  </LinkWrapper>
);

const footerData = {
  desc: {
    tag: "Groom Professional skills for your career.",
    desc:
      "We collaborate with ambitious people to help them achieve skills and tie them up with industries.",
    email: "hello@dibya.org"
  },
  social: [
    {
      text: "Medium",
      link: "https://medium.com/@dibya.org",
      icon: medium
    },
    {
      text: "Twitter",
      link: "https://twitter.com/dibya_org",
      icon: twitter
    },
    {
      text: "Facebook",
      link: "http://fb.me/dibyaOrg",
      icon: facebook
    },
    {
      text: "Linkedin",
      link: "https://www.linkedin.com/company/dibya",
      icon: linkedin
    },
    {
      text: "Github",
      link: "https://github.com/dibyaorg",
      icon: github
    }
  ],
  pages: [
    {
      text: "Home",
      link: "/"
    },
    {
      text: "Careers",
      link: "/career"
    },
    {
      text: "Contact",
      link: "/contact"
    },
    {
      text: "Blog",
      link: "/"
    }
  ]
};

export default function Footer() {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <FooterWrapper>
      <FooterContainer>
        <Grid container spacing={1} justify="center">
          <Grid item xs={12} sm={8}>
            <Tag>{footerData.desc.tag}</Tag>
            <Desc>{footerData.desc.desc}</Desc>
            <EmailLink href={ `mailto:${footerData.desc.email}` }>
              <Email>
                {footerData.desc.email}
              </Email>
            </EmailLink>
          </Grid>
          <Grid item xs={12} sm={4}>
            <IconContainer smallDevice={sm}>
              <Grid
                container
                direction="row"
                spacing={2}
                justify={sm ? "flex-start" : "flex-end"}>
                {footerData.social.map(socialLink => (
                  <Grid item key={socialLink.text.toString()}>
                    <LinkItemIcon {...socialLink} />
                  </Grid>
                ))}
              </Grid>
            </IconContainer>
          </Grid>
        </Grid>
      </FooterContainer>
    </FooterWrapper>
  );
}
