// @flow
import styled from 'styled-components';

const Button = styled.button`
  padding: ${({ small }) => small ? `0.25em 0.75em` : `0.5em 1.5em`};
  text-align: center;
  border-radius: ${({ small }) => small ? '24px' : '48px'};
  border: solid 1px ${({ theme, filled, disabled }) => (!disabled && !filled) ? theme.colors.primaryLight : null};
  background-color: ${({ theme, filled, disabled }) =>
     filled ? (disabled ? theme.colors.lightgray : theme.colors.primaryLight) : "transparent"};
  color: ${({ theme, filled, disabled }) => filled ? "white" : (disabled ? theme.colors.lightgray : theme.colors.primaryLight)};
  font-size: ${({ small }) => small ? '16px' : '24px'};
  line-height: 1.46;
  letter-spacing: normal;
  outline: none;
  cursor: pointer;

  :hover, :active, :focus {
    box-shadow: ${({ disabled }) => !disabled && '4px 10px 20px rgba(0,0,0,0.1)'};
    border: solid 1px
      ${({ theme, filled, disabled }) => disabled ? null : (filled ? theme.colors.primaryLight : "transparent")};
  }
`;

export default Button;
