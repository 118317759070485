import { createGlobalStyle } from 'styled-components';

// eslint-disable-next-line
export const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Poppins', sans-serif;
    overflow-x: hidden;
    margin: 0;
  } 
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  }
`;

export const theme = {
  colors: {
    primary: '#267FBA',
    primaryLight: '#35B1F1',
    primaryLighter: '#E3F6FD',
    success: '#26BAAB',
    secondary: '#26BAAB',
    grey: '#707070',
    lightgray: 'rgba(112, 112, 112, 0.54)',
    white: '#fff',
    border: '#e6e6e6',
    background: '#F4F4F4'
  },
  // TODO/NOTE: use spacing in styles
  spacing: {
    sm: '8px',
    md: '12px',
    xl: '24px',
    xxl: '36px',
    xxxl: '48px'
  }
};
