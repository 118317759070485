import React, { useEffect } from 'react';
import ReactGA from "react-ga";
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { ThemeProvider } from 'styled-components';
import { theme, GlobalStyle } from 'styled';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Home from 'pages/Home';
import Event from 'pages/Event';
import MuiTheme from 'material';

ReactGA.initialize('UA-142843659-1');

export default function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <MuiThemeProvider theme={MuiTheme}>
      <ThemeProvider theme={theme}>
        <Router>
          <GlobalStyle />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/events" component={Event} />
            <Redirect to="/" />>
          </Switch>
        </Router>
      </ThemeProvider>
    </MuiThemeProvider>
  );
}
