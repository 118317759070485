// @flow
import React, { Fragment, createRef } from 'react';

import { HeroPage } from 'components/common/Page';
import ArrowRight from 'components/common/Icon';
import { Feature } from 'components/home';
import { Subscribe } from 'components/home/Subscribe';
import HowItWorks from 'components/home/Stepper';
import { JoinDiscord } from 'components/home/Join';
import home from 'assets/home.png';

const homeData = {
  hero: {
    title: 'Level up with industry experts',
    desc: `Groom your interest with industry experts, learn beyond the fundamentals;
           a step forward to  your dream career`,
    img: home,
    page: "Home",
    button: <Fragment>Get early access <ArrowRight /></Fragment>
  }
};

export default function Home() {
  const subscribeElement = createRef();

  function goToSubscribe() {
    subscribeElement.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }

  return (
    <HeroPage
      goToSubscribe={goToSubscribe}
      heroProps={{ ...homeData.hero, onClick: goToSubscribe }}>
      <Feature />
      <JoinDiscord />
      <HowItWorks />
      <Subscribe reference={subscribeElement}/>
    </HeroPage>
  );
}
