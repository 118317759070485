import  createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import { theme } from 'styled';

const MuiTheme = createMuiTheme({
  palette: {
    primary: {
      main: theme.colors.primary
    },
    secondary: {
      main: theme.colors.secondary
    }
  },
  status: {
    danger: theme.colors.white,
  },
});

export default MuiTheme;
